const navigation = reactive([
  {
    label: 'Pro firmy',
    key: 'pro-firmy',
    href: '/',
    subMenu: [
      { label: 'Platba faktur kryptoměnami', href: '/platba-faktur-kryptomenami', key: 'invoice' },
      { label: 'Nákup kryptoměn na firmu', href: '/nakup-kryptomen-na-firmu', key: 'company' },
      { label: 'Směnárenský software', href: '/smenarensky-software', key: 'whitelabel' },
    ],
  },
  {
    label: 'Pro jednotlivce',
    key: 'pro-jednotlivce',
    href: '/',
    subMenu: [
      { label: 'Směna kryptoměn', href: '/smena-kryptomen', key: 'smena-kryptomen' },
      { label: 'Pravidelné spoření', href: '/smena-kryptomen#pravidelne-sporeni', key: 'pravidelne-sporeni' },
      { label: 'Affiliate', href: '/affiliate', key: 'affiliate' },
    ],
  },
  {
    label: 'Společnost',
    key: 'spolecnost',
    href: '/',
    subMenu: [
      { label: 'O nás', href: '/o-nas', key: 'about' },
      { label: 'Nejčastější otázky', href: '/faq', key: 'faq' },
      { label: 'Kurzovní lístek', href: '/kurzovni-listek', key: 'kurzovni-listek' },
      { label: 'Blog', href: '/blog', key: 'blog' },
      { label: 'Kontakty', href: '/kontakt', key: 'kontakt' },
    ],
  },
  {
    label: 'O kryptoměnách',
    key: 'o-kryptomenach',
    href: '/o-kryptomenach',
  },
])

const profileNavigation = [
  {
    label: 'Přehled',
    key: 'profile-overview',
    href: '/profile/overview',
    icon: 'i-fubex-square-menu',
  },
  {
    label: 'Směna',
    key: 'profile-exchange',
    href: '/profile/exchange',
    icon: 'i-fubex-exchange',
  },
  {
    label: 'Historie směn',
    key: 'profile-history',
    href: '/profile/history',
    icon: 'i-fubex-history',
  },
  {
    label: 'Můj účet',
    href: '/profile/account',
    key: 'profile',
    icon: 'i-fubex-settings',
  },
  {
    label: 'Bonusový program',
    key: 'profile-bonus',
    href: '/profile/bonus',
    icon: 'i-fubex-bonus',
  },
  {
    label: 'Odhlásit se',
    key: 'logout',
    href: '/logout',
    icon: 'i-fubex-logout',
  },
]
const mobileMenuOpen = ref(false)

export default function useNavigation () {
  const prepareNavigation = async () => {
    const links = await $fetch('/api/crypto/navigation-links')
    navigation.find(group => group.key === 'o-kryptomenach')!.subMenu = links
  }

  return {
    mobileMenuOpen,
    navigation,
    profileNavigation,
    prepareNavigation,
  }
}
